import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService } from './public/auth-guard.service';
import { AuthService } from './public/auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import * as Sentry from "@sentry/angular-ivy";
import { SharedHelperService } from './shared/shared-helper.service';
import { ToastrModule } from 'ngx-toastr';
import { FORM_ERROR_FN, getFormError } from './shared/common-form-components/form-field-validation';
import { ScrollBottomReachedDirective } from './shared/global-scroller-module/scroll-bottom-reached.directive';

Sentry.init({
  dsn: `${environment.sentryio}`,
  ignoreErrors: ['401 OK', '0 Unknown', '401 Unauthorized'],
  allowUrls: [
    /https?:\/\/((www)\.)?african100-media.s3.amazonaws\.com/,
    /https?:\/\/((www)\.)?movemeback-media.s3.amazonaws\.com/,
    /https?:\/\/((www)\.)?analytics.african100\.com/,
    /https?:\/\/((www)\.)?analytics.movemeback\.com/
  ],
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "/^https:\/\/movemeback\.com\/api/", /^https:\/\/african100\.com\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

/**
 * App module - Movemeback web app
 */
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    FlexLayoutModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
      positionClass: 'toast-bottom-left'
    }),
    ScrollBottomReachedDirective
  ],
  providers: [AuthGuardService,
    AuthService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    SharedHelperService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
