import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ScrollPositionService } from './scroll-position.service';


@Directive({
  selector: '[mmbWebAppScrollBottomReached]',
  standalone: true
})
export class ScrollBottomReachedDirective {

  // default set to 150px
  @Input() offset:number = 150;

  constructor(
    private el: ElementRef,
    private scrollPositionService: ScrollPositionService,
  ) {
    console.log(this.el.nativeElement);
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const scrollPosition = window.scrollY + window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;

    const totalScroll = scrollPosition + this.offset;
    const isBottomReached = this.scrollPositionService.bottomReached.getValue();

    if (totalScroll >= pageHeight && !isBottomReached) {
      this.scrollPositionService.bottomReached.next(true);
    } else if(totalScroll < pageHeight && isBottomReached){
      this.scrollPositionService.bottomReached.next(false);
    }
  }

}
